import React from "react"
import styled from "styled-components"
import themedProp from "./utilities/themedProp"
import { getObjectStyles } from "./utilities/themify"

const nameInTheme = "grid"

const StyledCol = styled.div`
  box-sizing: border-box;

  flex: 0 0 auto;
  width: ${props =>
    props.size
      ? (props.size /
          (themedProp(props.theme, "columns", "", [nameInTheme]) || 12)) *
          100 +
        "%"
      : `auto`};

  ${props =>
    //if offset prop exists then calculate the offset
    props.offset
      ? `margin-left: ${
          (props.offset /
            (themedProp(props.theme, "columns", "", [nameInTheme]) || 12)) *
          100
        }%;`
      : ""}

  ${props => {
    //calculate column widths at breakpoints
    const sizes =
      props.sizes || themedProp(props.theme, "colSizes", "", [nameInTheme])
    if (sizes) {
      let mediaQueries = ""

      const breakpoints = themedProp(props.theme, "breakpoints", "", [
        nameInTheme,
      ]) || {
        sm: "576px",
        md: "768px",
        lg: "992px",
        xl: "1200px",
        xxl: "1400px",
      }

      for (const index in sizes) {
        const breakpoint = breakpoints[index]

        mediaQueries += `@media (min-width: ${breakpoint}) {
        width: ${
          (sizes[index] /
            (themedProp(props.theme, "columns", "", [nameInTheme]) || 12)) *
          100
        }%;
      };
      `
      }
      return mediaQueries
    } else return ""
  }}


  ${props => {
    //calculate column widths at breakpoints

    const offsets =
      props.offsets || themedProp(props.theme, "offsets", "", [nameInTheme])

    if (offsets) {
      let mediaQueries = ""

      const breakpoints = themedProp(props.theme, "breakpoints", "", [
        nameInTheme,
      ]) || {
        sm: "576px",
        md: "768px",
        lg: "992px",
        xl: "1200px",
        xxl: "1400px",
      }

      for (const index in offsets) {
        const breakpoint = breakpoints[index]

        mediaQueries += `@media (min-width: ${breakpoint}) {
        margin-left: ${
          (offsets[index] /
            (themedProp(props.theme, "columns", "", [nameInTheme]) || 12)) *
          100
        }%;
      };
      `
      }
      return mediaQueries
    } else return ""
  }}
    ${props => {
    return props.theme.col && props.theme.col.styles
      ? getObjectStyles(props.theme.col.styles)
      : ""
  }}
`

export default function Col({ children, size, sizes, offset, offsets, style }) {
  return (
    <StyledCol
      style={style}
      size={size}
      sizes={sizes}
      offset={offset}
      offsets={offsets}
    >
      {children}
    </StyledCol>
  )
}
