import React, { useEffect, useContext } from "react"
import { Link, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import styled, { ThemeContext } from "styled-components"

import Seo from "../components/seo"
import Row from "../components/themableUI/row"
import Col from "../components/themableUI/col"

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

const WorkNode = styled.div`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

const WorkPage = ({ data }) => {
  const themeContext = useContext(ThemeContext)
  useEffect(() => {
    themeContext.setLink("")
  })

  return (
    <>
      <Seo title="Work" />
      <h2 className="screen-reader-text">work</h2>
      <Row>
        <Col size={12}>
          <PageContent>
            {/* <h2>Work</h2> */}

            {data.allMarkdownRemark.edges.map(post => {
              const featImage = getImage(post.node.frontmatter.featuredImage)
              const imgNode = featImage ? (
                <GatsbyImage image={featImage} alt="" />
              ) : (
                <></>
              )

              return (
                <WorkNode key={post.node.id}>
                  <Link
                    style={{ position: "relative" }}
                    to={post.node.frontmatter.path}
                  >
                    {/* <h3>{post.node.frontmatter.title}</h3> */}
                    {imgNode}
                    <p>Project: {post.node.frontmatter.title}</p>
                  </Link>
                  <p>Services: {post.node.frontmatter.services}</p>
                  <p>Tech: {post.node.frontmatter.tech}</p>
                </WorkNode>
              )
            })}
          </PageContent>
        </Col>
      </Row>
    </>
  )
}

export const pageQuery = graphql`
  query workIndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "work" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            date
            type
            tech
            services
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

export default WorkPage
