import React from "react"
import styled from "styled-components"
import themedProp from "./utilities/themedProp"
import { getObjectStyles } from "./utilities/themify"

const nameInTheme = "grid"

const StyledRow = styled.div`
  box-sizing: border-box;

  --gutter-x: ${props =>
    themedProp(props.theme, "gutter", "1.5rem", [nameInTheme])};
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gutter-y) * -1);
  margin-right: calc(var(--gutter-x) / -2);
  margin-left: calc(var(--gutter-x) / -2);

  > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x) / 2);
    padding-left: calc(var(--gutter-x) / 2);
    margin-top: var(--gutter-y);
  }

  ${props => {
    return props.theme.row && props.theme.row.styles
      ? getObjectStyles(props.theme.row.styles)
      : ""
  }}
`

export default function Row({ style, children }) {
  return <StyledRow style={style}>{children}</StyledRow>
}
